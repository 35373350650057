import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/container',
    name: 'container',
    component: () => import('@/views/container.vue'),
    children: [
      {
        path: '/contactus',
        name: 'contactus',
        component: () => import('@/views/contactus.vue')
      }, {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('@/views/aboutus.vue')
      }, {
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/privacy.vue')
      }, {
        path: '/joinus',
        name: 'joinus',
        component: () => import('@/views/joinus.vue')
      }
    ]
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/mobile/index.vue')
  },
  {
    path: '/mobileContainer',
    name: 'mobileContainer',
    component: () => import('@/views/mobile/container.vue'),
    children: [
      {
        path: '/mobileAboutus',
        name: 'mobileAboutus',
        component: () => import('@/views/mobile/aboutus.vue')
      },
      {
        path: '/mobileContactus',
        name: 'mobileContactus',
        component: () => import('@/views/mobile/contactus.vue')
      },
      {
        path: '/mobileprivacy',
        name: 'mobileprivacy',
        component: () => import('@/views/mobile/privacy.vue')
      },
      {
        path: '/mobilejoinus',
        name: 'mobilejoinus',
        component: () => import('@/views/mobile/joinus.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
